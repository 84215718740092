import useDomainContent from '@/domain/composables/use-domain-content'
import useLegalAgreement from '@/domain/composables/use-legal-agreement'
import LangService from '@/domain/services/lang-service'
import store from '@/store'
import i18n from '@/plugins/i18n'

export default class checkAgreementService {
  static async checkAgreement() {
    const domainId = store.state.domain.data.id
    const currentEventId = store.getters['event/currentEventId']
    const paramsLegalAgreement = {
      filter: { userId: store.state.auth.userId },
    }
    const contentItems = await useDomainContent().getAgreementItemList(
      domainId,
      currentEventId,
      i18n.locale
    )
    const requiredAgreements = contentItems.filter((item) => item.required)
    const userAgreements = await useLegalAgreement().getLegalAgreement(
      paramsLegalAgreement
    )
    if (!contentItems.length) {
      return
    }
    const allAgreementsApproved = requiredAgreements.every((required) => {
      const userAgreement = userAgreements.find(
        (agreement) => +agreement.contentId === +required.id
      )
      return userAgreement && userAgreement.approved === true
    })
    if (!allAgreementsApproved) {
      await LangService.toLocalePath({
        path: '/user',
        query: { agreement: true },
      })
    }
    return true
  }
}
