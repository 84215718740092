<template>
  <CheckboxGroup
    :value="value"
    :disabled="disabled"
    :class="{ warn: warningTextIsVisible }"
    @input="updateValue"
  >
    <Checkbox
      v-for="fieldValue in field.values"
      :key="fieldValue.id"
      :label="fieldValue.id"
      :class="{ 'checkbox-required': fieldValue.required }"
    >
      <div class="checkbox-agreement-name">
        <p>{{ fieldValue.confirmationText }}</p>
        <router-link
          :key="fieldValue.id"
          :to="localePath({ path: `/registration/juridical/${fieldValue.id}` })"
          class="content-items__link agreement-link"
          target="_blank"
        >
          {{ fieldValue.contentName }}
        </router-link>
        <div
          v-if="warningTextIsVisible && !value.includes(fieldValue.id)"
          class="checkbox-agreement-warning"
        >
          {{ fieldValue.warningText }}
        </div>
      </div>
    </Checkbox>
  </CheckboxGroup>
</template>

<script>
import InitValidation from '../mixins/init-validation'
import UpdateValue from '../mixins/update-value'

export default {
  mixins: [UpdateValue, InitValidation],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warningTextIsVisible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.ivu-checkbox-group {
  &.warn {
    display: flex;
    gap: 46px !important;
    flex-direction: column;
  }
}
.checkbox-required:after {
  content: '*';
  display: inline-block;
  margin-left: 4px;
  line-height: 1;
  font-family: SimSun;
  font-size: 14px;
  color: #fb4e4e;
  position: absolute;
}
.checkbox-agreement-name {
  display: inline-block;
  position: relative;
  p {
    padding-left: 4px;
    display: inline-block;
  }
  .agreement-link {
    padding-right: 0;
    margin-left: 4px;
  }
}
.checkbox-agreement-warning {
  position: absolute;
  top: 18px;
  left: 4px;
  color: #f6a609;
  font-size: 12px;
}
</style>
