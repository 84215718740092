import { getExistingApiInstance } from '@/api'

export default function useLegalAgreement() {
  async function createLegalAgreement(payload) {
    const api = getExistingApiInstance()
    const { data } = await api.legalAgreement.create(payload)
    return data
  }
  async function getLegalAgreement(params) {
    const api = getExistingApiInstance()
    const { data } = await api.legalAgreement.getMany(params)
    return data
  }
  return {
    createLegalAgreement,
    getLegalAgreement,
  }
}
