<template>
  <div class="registration" @keypress.enter="handleSubmit">
    <div class="auth__header auth-header">
      <div class="auth-header__actions">
        <div class="header-back">
          <router-link
            class="header-back__content s-card s-card--no-padding"
            :to="localePath({ path: '/login' })"
          >
            <span class="header-back__back-icon">
              <DirectionLeftIcon />
            </span>
            <span class="header-back__text s-subtitle-secondary">
              {{ $tr('registration.auth') }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <h1 v-if="!agreementIsVisible" class="registration__title auth__title">
      {{ $tr('registration.title') }}
    </h1>
    <h1 v-else class="registration__title auth__title">Юридические согласия</h1>
    <div
      v-if="!agreementIsVisible"
      class="s-card registration__registration-form registration-form"
    >
      <AppRegistrationUserForm v-model="userModel" />
    </div>
    <div
      v-else
      class="s-card registration__registration-form registration-form"
    >
      <CheckboxAgreement
        v-model="agreementIsAccept"
        :field="agreementField"
        :warningTextIsVisible="warningTextIsVisible"
      />
    </div>
    <div class="registration__registration-footer">
      <div class="row registration-footer">
        <div class="col-sm-12 col-lg-4 registration-footer__submit-wrapper">
          <Button
            v-if="!agreementIsVisible"
            :disabled="isLoading"
            type="primary"
            size="large"
            class="registration-footer__submit agreement-button"
            @click="handleToAgreement"
          >
            Далее
          </Button>
          <Button
            v-else
            :disabled="isLoading"
            type="primary"
            size="large"
            class="registration-footer__submit"
            @click="handleUserCreate"
          >
            {{ $tr('registration.next') }}
          </Button>
        </div>
        <div class="col-sm-12 col-lg-8">
          <AppContentItems class="registration-footer__content-items" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppRegistrationUserForm from '@/components/common/app-registration-user-form'
import AppContentItems from '@/components/common/app-content-items'
import AuthAuthStrategy from '@/domain/services/login-service/strategies/auth-auth-strategy'
import UserService from '@/domain/services/user-service'
import LangService from '@/domain/services/lang-service'
import EventService from '@/domain/services/event-service'
import DirectionLeftIcon from '@/components/themed-icons/direction-left'
import CheckboxAgreement from '@/components/common/field-renderer/fields/checkboxAgreement.vue'
import useDomainContent from '@/domain/composables/use-domain-content'
import useLegalAgreement from '@/domain/composables/use-legal-agreement'

export default {
  components: {
    AppRegistrationUserForm,
    DirectionLeftIcon,
    AppContentItems,
    CheckboxAgreement,
  },
  data() {
    return {
      userModel: {
        email: this.$route.query.email,
        password: '',
        passwordRepeat: '',
      },
      isLoading: false,
      isDataSent: false,
      agreementIsVisible: false,
      agreementIsAccept: [],
      contentItems: [],
      agreementField: {
        values: [],
      },
      warningTextIsVisible: false,
      paramsLegalAgreement: {
        filter: { userId: this.$store.state.auth.userId },
      },
    }
  },

  created() {
    const domainId = this.$store.state.domain.data.id
    const currentEventId = this.$store.getters['event/currentEventId']

    this.getContentItemList(domainId, currentEventId)

    if (this.$route.query.agreement) {
      this.agreementIsVisible = true
      this.userAgreement()
    }
  },

  computed: {
    ...mapState({
      eventData: (state) => state.event.data,
    }),
    requiredAgreements() {
      return this.contentItems.filter((item) => item?.required)
    },
    notRequiredAgreements() {
      return this.contentItems.filter((item) => !item?.required)
    },
  },
  methods: {
    handleSubmit() {
      if (this.agreementIsVisible) {
        this.handleUserCreate()
      } else {
        this.handleToAgreement()
      }
    },
    async getContentItemList(domainId, eventId) {
      try {
        const data = await useDomainContent().getAgreementItemList(
          domainId,
          eventId,
          this.$i18n.locale
        )
        this.contentItems = data.reverse()
        this.agreementField.values = this.contentItems.map((item) => ({
          id: item.id,
          value: item.id,
          confirmationText: item.confirmationText,
          contentName: item.name,
          warningText: item.warningText,
          required: item.required,
        }))
      } catch (e) {
        console.error(e)
      }
    },
    handleToAgreement() {
      if (!this.contentItems.length) {
        this.handleUserCreate()
      } else {
        this.agreementIsVisible = true
      }
    },
    async toStartPage() {
      const pages = this.$store.state.pages.data
      const startPagePath = EventService.defineStartPagePath(
        this.eventData,
        pages
      )
      await LangService.toLocalePath({ path: startPagePath })
    },
    async handleUserCreate() {
      const isUserFormValid = this.userModel.isValid
      if (this.contentItems.length) {
        const allRequiredAccepted = this.requiredAgreements.every((item) =>
          this.agreementIsAccept.includes(String(item.id))
        )
        const notAcceptedAgreements = this.notRequiredAgreements.filter(
          (item) => !this.agreementIsAccept.includes(String(item.id))
        )

        if (notAcceptedAgreements.length && !this.warningTextIsVisible) {
          this.warningTextIsVisible = true
          return
        }

        if (!allRequiredAccepted) {
          this.$Message.error('Вы не приняли все обязательные соглашения')
          return
        }
      }

      this.isLoading = true
      if (this.$route.query.agreement) {
        await this.createAgreement()
        if (this.$route.query.fromRegistration) {
          await LangService.toLocalePath({ path: '/registration' })
        } else {
          await this.toStartPage()
        }
        return
      }
      try {
        if (isUserFormValid && !this.isDataSent) {
          delete this.userModel.isValid

          await this.createUser()
          await this.authUser()
          if (this.contentItems.length) {
            await this.createAgreement()
          }
          this.isDataSent = true
          await LangService.toLocalePath({ path: '/registration' })
        }
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
    async authUser() {
      const authAuthStrategy = new AuthAuthStrategy(
        this.eventData,
        this.userModel
      )
      try {
        await authAuthStrategy.execute()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async createUser() {
      const user = await UserService.createUser(
        this.userModel.email,
        this.userModel.password
      )
      this.$store.commit('auth/SET_USER_DATA', user)
    },
    async createAgreement() {
      const agreementIds = this.contentItems
        .filter((item) => this.agreementIsAccept.includes(String(item.id)))
        .map((item) => +item.id)
      try {
        const payload = {
          userId: +this.$store.state.auth.userId,
          eventId: +this.$store.getters['event/currentEventId'],
          contentIds: agreementIds,
        }
        return await useLegalAgreement().createLegalAgreement(payload)
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async userAgreement() {
      const userAgreements = await useLegalAgreement().getLegalAgreement(
        this.paramsLegalAgreement
      )
      this.agreementIsAccept = userAgreements.map((item) =>
        String(item.contentId)
      )
    },
  },
}
</script>
<style lang="scss">
@import '~@/styles/pages/registration-page.scss';
@import '~@/styles/pages/login.scss';
@import '~@/styles/blocks/header-back.scss';
</style>
